import * as React from "react";
import { useState } from "react";
import { useMedia } from "react-use";
import * as s from "../../styles/globalStyles";
import { Grid } from "@mui/material";
// import { Button } from "@mui/material";
import Modal from "react-modal";
import { Carousel } from "3d-react-carousal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

// モーダルウィンドウのスタイル定義
// https://reactcommunity.org/react-modal/styles/

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "800px",
    backgroundColor: "black",
    border: "0px",
    backgroundImage: `url(${require("../../images/bg-intro-gray.png")})`,
    textAlign: "center",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(25, 25, 25, 0.75)",
  },
};

export default function ArmsIndex() {
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);

  const [values, setValues] = useState({
    name: "",
    number: 0,
  });

  const isWide = useMedia("(min-width: 834px)");

  Modal.setAppElement("#root");

  // 名前とmanafudaマスタのindexを紐付けるオブジェクト
  let ids = {
    sato: 0,
    suzuki: 1,
    tanaka: 2,
  };

  // manafudaとnamesarms情報を集約したマスタオブジェクト
  let manafuda = [
    {
      name: "sato",
      NAME: "SATO",
      name_kanji: "佐藤",
      img: "./../../images/armsindex/manafuda_sato.png",
      detail: (
        <>
          佐藤姓に配られるウェポンセット｡
          <br />
          <br />
          剣3種・銃2種・盾1種からなる全距離型装備であり、武器種が多いため初心者でも使いやすい。
          <br />
          <br />
          だが、全武器をフルに使いこなすためには相応の実力が要求される。
        </>
      ),
      arms: [
        { id: 0, name: "none", img: "", detail: "" },
        {
          id: 1,
          name: "NIN-BEN BLADE",
          img: "./../../images/armsindex/sato_1.png",
          detail: (
            <>
              「にんべん(亻)」を象った鉈型のアームズ。
              <br />
              <br />
              近距離戦に適しており、ビギナーでも取り回しやすい。大振りな刃から繰り出される強力無比な一撃が特徴。
            </>
          ),
        },
        {
          id: 2,
          name: "LEFT BULLET",
          img: "./../../images/armsindex/sato_2.png",
          detail: (
            <>
              「左」を象ったハンドガン型のアームズ。
              <br />
              <br />
              縦に並んだ3つの銃口を持つトリプルバレル構造が特徴。高い連射性能を誇り、広範囲の対象を一挙に殲滅できる。
            </>
          ),
        },
        {
          id: 3,
          name: "SOU BLADE",
          img: "./../../images/armsindex/sato_3.png",
          detail: (
            <>
              「くさかんむり＝艸(ｿｳ)部」を象った双刃刀型のアームズ。
              <br />
              <br />
              両端に刀身を持つのが特徴。トリッキーな戦法を可能にするが取り回しはやや難しく、中級者向けのアームズといえる。
            </>
          ),
        },
        {
          id: 4,
          name: "TSUKI-HEN BLADE / BULLET",
          img: "./../../images/armsindex/sato_4.png",
          detail: (
            <>
              ｢つきへん (月)｣を象った剣/銃型アームズ。
              <br />
              <br />
              持ち手を変えることで剣と銃の2形態を使い分けられるのが特徴。近距離戦にも遠距離戦にも即応できるマルチウェポンである。
            </>
          ),
        },
        {
          id: 5,
          name: "N/A SHIELD 01",
          img: "./../../images/armsindex/sato_5.png",
          detail: (
            <>
              漢字の一部を象った盾型のアームズ｡
              <br />
              <br />
              部首としての固有名が無いため｢N/A｣に分類される。中心部のホロサイトを銃型アームズと併用すれば、盾越しに対象を狙撃できる。
            </>
          ),
        },
      ],
    },
    {
      name: "suzuki",
      NAME: "SUZUKI",
      name_kanji: "鈴木",
      img: "./../../images/armsindex/manafuda_suzuki.png",
      detail: (
        <>
          鈴木姓に配られるウェポンセット｡
          <br />
          <br />
          3種の剣からなる近距離型装備であり、巨大のブレードで敵を破断する豪快なファイトスタイルが特徴。
          <br />
          <br />
          ただし武器種が剣のみのため、戦法の幅は限られる。
        </>
      ),
      arms: [
        { id: 0, name: "none", img: "", detail: "" },
        {
          id: 1,
          name: "KANE-HEN BLADE",
          detail: (
            <>
              ｢かねへん (金)｣を象った大剣型のアームズ｡
              <br />
              <br />
              超大型の刀身から繰り出される重量級の斬撃が持ち味。幅広の刃は相手の攻撃から身を守る盾にもなる。
            </>
          ),
        },
        {
          id: 2,
          name: "REI BLADE",
          detail: (
            <>
              「令」を象った大剣型のアームズ。
              <br />
              <br />
              重量級の斬撃が持ち味。KANE-HEN
              BLADEと構造が似ており、合わせて双剣として用いるネームズもいる。
            </>
          ),
        },
        {
          id: 3,
          name: "K.I. BLADE",
          detail: (
            <>
              「木」を象った大剣型のアームズ。
              <br />
              <br />
              三叉に分かれた超大型の刀身をもつ。相手のアームズを破断するほどの威力を誇るが、相応の膂力がなければ持ち上げることすら叶わない。
            </>
          ),
        },
      ],
    },
    {
      name: "tanaka",
      NAME: "TANAKA",
      name_kanji: "田中",
      img: "./../../images/armsindex/manafuda_tanaka.png",
      detail: (
        <>
          田中姓に配られるウェポンセット｡
          <br />
          <br />
          盾と斧からなる中距離型装備であり、攻守共に優れた重量級のファイトスタイルが特徴。
          <br />
          <br />
          ただしどの武器もウェイトが重いため、機動力では他のアームズに劣る。
        </>
      ),
      arms: [
        { id: 0, name: "none", img: "", detail: "" },
        {
          id: 1,
          name: "T.A. SHIELD",
          img: "./../../images/armsindex/tanaka_1.png",
          detail: (
            <>
              「田」を象った盾型のアームズ。
              <br />
              <br />
              圧倒的な耐久力を誇る鉄壁のシールド。フチ部分は鋭利なブレードになっており、投げることで手裏剣のように用いることも可能。
            </>
          ),
        },
        {
          id: 2,
          name: "NAK-AX",
          img: "./../../images/armsindex/tanaka_2.png",
          detail: (
            <>
              「中」を象った斧型のアームズ。
              <br />
              <br />
              大きすぎず小さすぎない絶妙なサイズが特徴であり、中距離戦において抜群の汎用性を発揮する。
            </>
          ),
        },
      ],
    },
  ];

  //　真名札をクリックした位置に応じて武器番号を決定する関数のマスタ
  const fn = {
    sato: (e) => {
      if (
        (e.clientX - e.target.getBoundingClientRect().x) /
          e.target.getBoundingClientRect().width <
        0.22
      ) {
        setValues({ ...values, number: "1" });
      } else if (
        (e.clientX - e.target.getBoundingClientRect().x) /
          e.target.getBoundingClientRect().width <
        0.5
      ) {
        setValues({ ...values, number: "2" });
      } else if (
        (e.clientY - e.target.getBoundingClientRect().y) /
          e.target.getBoundingClientRect().height <
        0.23
      ) {
        setValues({ ...values, number: "3" });
      } else if (
        (e.clientX - e.target.getBoundingClientRect().x) /
          e.target.getBoundingClientRect().width <
        0.65
      ) {
        setValues({ ...values, number: "4" });
      } else {
        setValues({ ...values, number: "5" });
      }
      setEditModalIsOpen(true);
    },
    suzuki: (e) => {
      if (
        (e.clientX - e.target.getBoundingClientRect().x) /
          e.target.getBoundingClientRect().width <
        0.25
      ) {
        setValues({ ...values, number: "1" });
      } else if (
        (e.clientX - e.target.getBoundingClientRect().x) /
          e.target.getBoundingClientRect().width <
        0.5
      ) {
        setValues({ ...values, number: "2" });
      } else {
        setValues({ ...values, number: "3" });
      }
      setEditModalIsOpen(true);
    },
    tanaka: (e) => {
      if (
        (e.clientX - e.target.getBoundingClientRect().x) /
          e.target.getBoundingClientRect().width <
        0.5
      ) {
        setValues({ ...values, number: "1" });
      } else {
        setValues({ ...values, number: "2" });
      }
      setEditModalIsOpen(true);
    },
  };

  // カルーセルのリスト、真名札マスタから自動生成
  const slides = manafuda.map((data) => (
    <s.SlideManafudaImg
      src={require("./../../images/armsindex/manafuda_" + data.name + ".png")}
      alt={data.name}
      onClick={() => {
        if (data.name != values.name) {
          setValues({ ...values, name: data.name });
        }
      }}
    ></s.SlideManafudaImg>
  ));

  return (
    <s.Screen style={{ backgroundColor: "black", minHeight: "100vh" }}>
      <link
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
        rel="stylesheet"
        integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
        crossOrigin="anonymous"
      />
      <s.Container
        flex={2}
        jc={"center"}
        ai={"center"}
        color={"black"}
        bg_blend={"overlay"}
      >
        {isWide && <s.SpacerLarge />}
        {isWide && <s.SpacerMedium />}
        <s.Title>Arms Index</s.Title>
        <s.Divider width="100" />
        {values.name === "" ? (
          <>
            <s.TextDescription style={{ textAlign: "center" }}>
              真名札を選択して下さい
            </s.TextDescription>
            <s.SpacerLarge />
            <Grid
              container
              justify="center"
              spacing={3}
              xs={12}
              md={12}
              lg={10}
            >
              {manafuda.map((data) => (
                <Grid
                  item
                  key={data.name}
                  xs={6}
                  md={4}
                  lg={3}
                  style={{ padding: "0 10px" }}
                >
                  <s.ManafudaImg
                    src={require(`./../../images/armsindex/manafuda_${data.name}.png`)}
                    alt={data.name}
                    onClick={() => setValues({ ...values, name: data.name })}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <>
            <Grid container>
              <Grid item xs={0} sm={2} md={2} lg={3} />
              <Grid item xs={12} sm={8} md={8} lg={6}>
                <s.IndexContainer
                  // flex={2}
                  image={require("../../images/bg-intro-gray.png")}
                  color={"rgba(52, 52, 52, 0.7)"}
                  bg_blend={"overlay"}
                >
                  <Box
                    style={{
                      height: 0,
                      textAlign: "right",
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        setValues({ ...values, name: "" });
                      }}
                      style={{ color: "white" }}
                    >
                      <CloseIcon color="inherit" />
                    </IconButton>
                  </Box>
                  {values.name != null ? (
                    <>
                      <Arms
                        name={manafuda[ids[values.name]].name_kanji}
                        name_en={manafuda[ids[values.name]].NAME}
                        fn={fn[values.name]}
                        image={require("./../../images/armsindex/" +
                          values.name +
                          ".png")}
                        detail={manafuda[ids[values.name]].detail}
                      />
                    </>
                  ) : (
                    <s.TextDescription style={{ textAlign: "center" }}>
                      NO DATA.
                    </s.TextDescription>
                  )}
                </s.IndexContainer>
                {/* オートプレイにすると切り替わりのタイミングでレンダリングされる。*/}
                {values.name !== "" && (
                  <>
                    <div
                      style={{ backgroundColor: "black", minHeight: "160px" }}
                    >
                      <Carousel
                        key={values.name}
                        slides={slides}
                        autoplay={true}
                        interval={5000}
                      />
                    </div>
                  </>
                )}
              </Grid>
              <Grid item xs={0} sm={2} md={2} lg={3} />
            </Grid>
          </>
        )}
        <Modal
          isOpen={editModalIsOpen}
          style={customStyles}
          onRequestClose={() => {
            setEditModalIsOpen(false);
          }}
        >
          <Box
            style={{
              height: 0,
              textAlign: "right",
            }}
          >
            <IconButton
              onClick={() => {
                setEditModalIsOpen(false);
              }}
              style={{ color: "white", margin: "-25px -20px 0px 0px" }}
            >
              <CloseIcon color="inherit" />
            </IconButton>
          </Box>
          {values.name == "" ||
          manafuda[ids[values.name]].arms[values.number] == null ? (
            <s.TextDescription style={{ textAlign: "center" }}>
              Coming soon.
            </s.TextDescription>
          ) : (
            <ArmsDetail
              name={values.name}
              armsname={manafuda[ids[values.name]].arms[values.number].name}
              num={values.number}
              detail={manafuda[ids[values.name]].arms[values.number].detail}
            />
          )}
        </Modal>
      </s.Container>
    </s.Screen>
  );
}

function Arms(props) {
  return (
    <>
      <div style={{ margin: "2%" }}>
        <s.ArmsTitle>ネームズアームズ「{props.name}」</s.ArmsTitle>
        <s.ArmsSubTitle>NAMES' ARMS: {props.name_en}</s.ArmsSubTitle>
        <Grid container style={{ textAlign: "center" }}>
          <Grid item xs={12} md={12} lg={12}>
            <s.SpacerSmall />
            <s.SelectArmsImg src={props.image} onClick={props.fn} />
            <s.TextDescription
              style={{ textAlign: "center", fontSize: "14px" }}
            >
              ※各武器をクリックすると詳細が開示されます。
            </s.TextDescription>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <s.IndexTextContainer>
              <s.ArmsDetailText style={{ textAlign: "left" }}>
                {props.detail}
              </s.ArmsDetailText>
            </s.IndexTextContainer>
            <s.SpacerSmall />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

function ArmsDetail(props) {
  return (
    <>
      {props.num == 0 ? (
        <s.TextDescription style={{ textAlign: "center" }}>
          Coming soon.
        </s.TextDescription>
      ) : (
        <Grid container style={{ textAlign: "center" }}>
          <Grid
            item
            xs={6}
            md={4}
            lg={4}
            style={{ verticalAlign: "middle", alignItems: "center" }}
          >
            <Grid
              container
              alignItems="center"
              justify="center"
              style={{ height: "100%" }}
            >
              <Grid item alignSelf="center" style={{ textAlign: "center" }}>
                <s.OnlyArmsImg
                  src={require("./../../images/armsindex/" +
                    props.name +
                    "_" +
                    props.num +
                    ".png")}
                />
                {/* ここに文字を入れるとなぜか中央に表示された */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={8} lg={8} >
            <s.ArmsTitle style={{ textAlign: "left" }}>
              {props.armsname}
            </s.ArmsTitle>
            <s.SpacerMedium />
            <s.IndexTextContainer>
              <s.ArmsDetailText style={{ textAlign: "left"}}>
                {props.detail}
              </s.ArmsDetailText>
            </s.IndexTextContainer>
          </Grid>
        </Grid>
      )}
    </>
  );
}
