import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import Link from "@mui/material/Link";
import { useMedia } from "react-use";
import logo from "./../../images/logo.png";

import AnchorLink from "react-anchor-link-smooth-scroll";

import { Box, Button, Drawer } from "@mui/material";
import { Grid } from "@mui/material";
import styled from "@emotion/styled";
// import { styled } from '@mui/styles';

const StyledLogo = styled.img`
  max-height: 40px;
`;

const HeadButton = styled(Button)`
  text-transform: none;
  color: white;

  font-family: "Torono Glitch Sans";
`;

// const HeadButton = styled(ListItemButton)`
//   text-transform: none;
//   color: white;

// `;

const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ color }) => (color ? color : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  background-blend-mode: ${({ bg_blend }) => (bg_blend ? bg_blend : "normal")};
  font-family: "Torono Glitch Sans";
`;

export default function Header() {
  const isWide = useMedia("(min-width: 834px)");

  return <>{isWide ? <WideHeader /> : <ShortHeader />}</>;
}

function WideHeader() {
  return (
    <Box sx={{ flexGrow: 1 }} style={{}}>
      <AppBar
        position="static"
        style={{ backgroundColor: "black", position: "fixed", top: 0 }}
      >
        <Toolbar>
          <Grid container>
            <Grid item xs={2}>
              <Link href="/">
                <StyledLogo src={logo} />
              </Link>
            </Grid>
            <Grid item xs={5} style={{ flexGrow: 1 }} />

            <Grid item xs={1} sm={1}>
              <HeadButton href="/#about" color="inherit">
                About
              </HeadButton>
            </Grid>
            <Grid item xs={1} sm={1}>
              <HeadButton href="/#intro" color="inherit">
                Intro
              </HeadButton>
            </Grid>
            <Grid item xs={1} sm={1}>
              <HeadButton href="/armsindex" color="inherit">
              {/* <HeadButton href="/#index" color="inherit"> */}
                Index
              </HeadButton>
            </Grid>
            <Grid item xs={1} sm={1}>
              <HeadButton href="/#roadmap" color="inherit">
                Roadmap
              </HeadButton>
            </Grid>
            <Grid item xs={1} sm={1}>
              <HeadButton href="/#team" color="inherit">
                Team
              </HeadButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

function ShortHeader() {
  const [open, setopen] = useState(false);
  const toggleOpen = () => {
    setopen(!open);
  };

  return (
    <Box sx={{ flexGrow: 1 }} style={{ position: "sticky", top: "0", zIndex: 999}}>
      <AppBar
        position="static"
        style={{ backgroundColor: "black", }}
      >
        <Toolbar>
          <Grid container>
            <Grid item xs={5}>
              <Link href="/">
                <StyledLogo src={logo} />
              </Link>
            </Grid>
            <Grid item xs={6} style={{ flexGrow: 1 }} />
            <Grid item xs={1}>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={toggleOpen}
              >
                <MenuIcon />
              </IconButton>
              <Drawer anchor="right" open={open} onClose={toggleOpen}>
                <Container flex={1} color={"black"}>
                  <List>
                  <ListItem></ListItem>
                    <ListItem>
                      <ListItemButton href="/#about" onClick={toggleOpen}>
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography
                              variant="body1"
                              style={{
                                color: "white",
                                fontFamily: "Torono Glitch Sans",
                              }}
                            >
                              About
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                    <ListItem>
                      <ListItemButton href="/#intro" onClick={toggleOpen}>
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography
                              variant="body1"
                              style={{
                                color: "white",
                                fontFamily: "Torono Glitch Sans",
                              }}
                            >
                              Intro
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                    <ListItem>
                      <ListItemButton href="/armsindex" onClick={toggleOpen}>
                      {/* <ListItemButton href="/#index" onClick={toggleOpen}> */}
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography
                              variant="body1"
                              style={{
                                color: "white",
                                fontFamily: "Torono Glitch Sans",
                              }}
                            >
                              Arms Index
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                    <ListItem>
                      <ListItemButton href="/#roadmap" onClick={toggleOpen}>
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography
                              variant="body1"
                              style={{
                                color: "white",
                                fontFamily: "Torono Glitch Sans",
                              }}
                            >
                              Roadmap
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                    <ListItem>
                      <ListItemButton href="/#team" onClick={toggleOpen}>
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography
                              variant="body1"
                              style={{
                                color: "white",
                                fontFamily: "Torono Glitch Sans",
                              }}
                            >
                              Team
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Container>
              </Drawer>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
