import * as React from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import * as s from "./styles/globalStyles";
import { Grid } from "@mui/material";
import { useMedia } from "react-use";
import { TwitterTweetEmbed } from "react-twitter-embed";
import { Timeline, Tweet } from "react-twitter-widgets";
import { Box, Button, Drawer } from "@mui/material";
import styled from "@emotion/styled";

import ArmsIndex from "./components/pages/Armsindex";
import Header from "./components/modules/Header";
import Firstview from "./images/bg-masthead.png";
import Cyber from "./images/bg-cyber.png";
import Introduction from "./images/intro_new.png";
import IntroductionL from "./images/intro_new_big.png";
import IntroBg from "./images/bg-intro-new.png";
import IndexBg from "./images/bg-index.png";

const LinkButton = styled(Button)`
  text-transform: none;
  color: white;
`;

function App() {
  return (
    <>
      <s.GlobalStyle />
      <Header />
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/armsindex">
            <ArmsIndex />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </BrowserRouter>
      <Footer />
    </>
  );
}

function Home() {
  const isWide = useMedia("(min-width: 834px)");

  return (
    <s.Screen style={{ backgroundColor: "black" }}>
      <s.Firstview src={Firstview} />
      <section id="new">
        <New />
      </section>
      <section id="about">
        <About />
      </section>

      <section id="intro">
        <Intro />
      </section>
      {/* <Content /> */}
      <section id="index">
        {/* <ArmsIndex /> */}
        {/* 仮 ここから */}
        <s.Container
          flex={2}
          jc={"center"}
          ai={"center"}
          bg_blend={"overlay"}
          image={IndexBg}
          color={"rgba(30, 30, 30, 0.9)"}
        >
          <link
            href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
            rel="stylesheet"
            integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
            crossorigin="anonymous"
          />
          <s.SpacerLarge />
          <s.SpacerLarge />
          <s.SpacerLarge />
          <s.Title>Arms Index</s.Title>
          <s.Divider width="100" />
          <s.TextDescription style={{ textAlign: "center" }}>
            名前から生まれた武器...ネームズアームズ。
            <br />
            その秘密が詰まった武器図鑑を特別公開中。
          </s.TextDescription>
          {isWide ? (
            <LinkButton
              href="/armsindex"
              color="inherit"
              style={{
                fontFamily: "Torono Glitch Sans",
                textDecoration: "underline",
              }}
            >
              CLICK into the Index ▶︎
            </LinkButton>
          ) : (
            <LinkButton
              href="/armsindex"
              color="inherit"
              style={{
                fontFamily: "Torono Glitch Sans",
                textDecoration: "underline",
              }}
            >
              TAP into the Index ▶︎
            </LinkButton>
          )}
          <s.SpacerLarge />
          <s.SpacerLarge />
        </s.Container>
        {/* 仮 ここまで */}
      </section>
      <section id="roadmap">
        <Roadmap />
      </section>
      <section id="team">
        <Team />
      </section>
      {/* <Content /> */}
    </s.Screen>
  );
}

function New() {
  const isWide = useMedia("(min-width: 834px)");

  return (
    <s.Container flex={2} jc={"center"} ai={"center"} bg_blend={"overlay"}>
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.Title>NEWS</s.Title>
      <s.Divider width="100" />
      <s.TextDescription style={{ textAlign: "center", fontSize: "20px" }}>
        <strong>【オリジンコレクション リリース】</strong>
      </s.TextDescription>
      <Grid container>
        <Grid item xs={1} sm={2} md={2} lg={3} />
        <Grid item xs={10} sm={8} md={8} lg={6}>
          <s.TextDescription style={{ textAlign: "center" }}>
            第一弾NFTをリリースします。<br />
            オリジンヒーローの苗字は各1枚のみ。<br />
            最強ユーティリティのNFTをGETして<br />
            プロジェクトに参加しよう！
          </s.TextDescription>
          <s.SpacerSmall />
          <s.SelectArmsImg
            src={require("./images/origin.png")}
            style={{ maxWidth: "900px", textAlign: "center"  }}
          />
        </Grid>
        <Grid item xs={1} sm={2} md={2} lg={3} />
      </Grid>
      <s.TextDescription style={{ textAlign: "center", fontSize: "20px" }}>
        <strong>【テーマソング配信開始】</strong>
      </s.TextDescription>
      <Grid container>
        <Grid item xs={1} sm={2} md={2} lg={3} />
        <Grid item xs={10} sm={8} md={8} lg={6}>
          <s.TextDescription style={{ textAlign: "center" }}>
            ネームズアームズのテーマソングが2023年3月24日18時から配信開始。
            <s.SpacerXSmall />
            音楽NFTプラットフォーム「
            <a
              href="https://sound-desert.com/artists/namesarms"
              style={{ fontFamily: "SourceHanSerif" }}
            >
              SoundDesert
            </a>
            」にてリリースされます。
            <s.SpacerXSmall />※
            購入にはMetamask等の暗号資産ウォレットが必要です。
          </s.TextDescription>
          <s.SpacerSmall />
        </Grid>
        <Grid item xs={1} sm={2} md={2} lg={3} />
      </Grid>
      <Grid container>
        <Grid item xs={1} sm={2} md={2} lg={4} />
        <Grid item xs={10} sm={8} md={8} lg={4} style={{ textAlign: "center" }}>
          <s.SelectArmsImg
            src={require("./images/jacket_final.png")}
            style={{ maxWidth: "300px" }}
          />

          <s.TextDescription style={{ textAlign: "center" }}>
            <div style={{ fontSize: "22px", fontFamily: "Torono Glitch Sans" }}>
              CODE of...My Name
            </div>
            <s.SpacerXSmall />
            作詞・作曲・編曲：藤末樹 <br />
            歌：ヨシカ
            <br />
            ギター：奥山アキラ
            <br />
            イラスト：ザギザギ
            <s.SpacerSmall />
            作詞・作曲・編曲を担当する藤末樹氏は、『仮面ライダー剣』の後期OP「ELEMENTS」を始め、400曲以上のJPOP提供実績を誇る作曲家です。
            <s.SpacerXSmall />
            藤末氏の手がけるハードでビビッドな音楽の世界を<br />
            「<a
              href="https://sound-desert.com/artists/namesarms"
              style={{ fontFamily: "SourceHanSerif" }}
            >
              SoundDesert
            </a>」でご堪能ください。
          </s.TextDescription>
        </Grid>
        <Grid item xs={1} sm={2} md={2} lg={4} />
      </Grid>

      {/* <Grid container style={{ maxWidth: "800px" }}>
        <Grid item xs={1} sm={1} md={0} lg={0} />
        <Grid item xs={10} sm={10} md={12} lg={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} style={{ textAlign: "center" }}>
              <s.SelectArmsImg
                src={require("./images/code_of_my_name_sample.jpeg")}
                style={{ maxWidth: "300px" }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} style={{ textAlign: "center" }}>
              <s.TextDescription style={{ textAlign: "center" }}>
                <div
                  style={{ fontSize: "22px", fontFamily: "Torono Glitch Sans" }}
                >
                  CODE of...My Name
                </div>
                <s.SpacerXSmall />
                作詞・作曲・編曲：藤末樹 <br />
                歌：ヨシカ
                <br />
                ギター：奥山アキラ
                <br />
              </s.TextDescription>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} sm={1} md={0} lg={0} />
      </Grid> */}
      <s.SpacerLarge />
      <s.SpacerLarge />
    </s.Container>
  );
}

function About() {
  const isWide = useMedia("(min-width: 834px)");

  return (
    <s.Container
      flex={2}
      jc={"center"}
      ai={"center"}
      image={Cyber}
      color={"rgba(52, 52, 52, 0.9)"}
      bg_blend={"overlay"}
    >
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.Title>PJ NAMES/ARMS</s.Title>
      <s.Divider width="100" />
      <s.TextDescription style={{ textAlign: "center", fontSize: "20px" }}>
        <strong>俺たちのヒーローを、俺たちの手で</strong>
      </s.TextDescription>
      <Grid container>
        <Grid item xs={1} sm={2} md={2} lg={3} />
        <Grid item xs={10} sm={8} md={8} lg={6}>
          <s.TextDescription style={{ textAlign: "center" }}>
            プロジェクト「ネームズアームズ」は、しがらみの無いWeb3の世界を起点に、
            {isWide && <br />}
            新たなオリジナルヒーローを作り出すプロジェクト。
            {/* <br className="sp-only" /> */}
            <s.SpacerSmall />
            クリエイター ザギザギ氏の生み出す世界観をベースにコンテンツを作り、
            {isWide && <br />}
            共に楽しめるコミュニティを目指します。
            <s.SpacerSmall />
            discordコミュニティ運営、ザギザギ氏の手掛ける
            {isWide && <br />}
            イラストNFT販売等を計画しています。
            <br />
          </s.TextDescription>
        </Grid>
        <Grid item xs={1} sm={2} md={2} lg={3} />
      </Grid>
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.SpacerLarge />
    </s.Container>
  );
}

function Intro() {
  const isWide = useMedia("(min-width: 900px)");

  return (
    <s.Container
      flex={2}
      jc={"center"}
      ai={"center"}
      image={IntroBg}
      // color={"rgba(52, 52, 52, 0.9)"}
      bg_blend={"overlay"}
    >
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.Title>Introduction</s.Title>
      <s.Divider width="100" />
      {isWide ? (
        <s.StyledIntroImgL src={IntroductionL} />
      ) : (
        <s.StyledIntroImg src={Introduction} />
      )}

      <s.SpacerLarge />
    </s.Container>
  );
}

function Content() {
  return (
    <>
      <s.Container
        flex={1}
        jc={"center"}
        ai={"center"}
        color={"black"}
        bg_blend={"overlay"}
      >
        <s.SpacerLarge />
        <s.SpacerLarge />
        <s.SpacerLarge />
        <s.Title>NFT</s.Title>
        <s.Divider width="100" />
        {/* テーマソング告知 */}
        <s.TextDescription style={{ textAlign: "center" }}>
          2023年3月24日に音楽NFTプラットフォームSoundDesertにて、
          <br />
          ネームズアームズのテーマソングを音楽NFTとしてリリース致します。
          <s.SpacerSmall />※ 購入にはMetamask等の仮想通貨ウォレットが必要です。
        </s.TextDescription>
        <s.SpacerSmall />
        {/* <s.SelectArmsImg
          src={require("./images/code_of_my_name_sample.jpeg")}
        /> */}
        <s.TextDescription style={{ textAlign: "center" }}>
          <div style={{ fontSize: "22px", fontFamily: "Torono Glitch Sans" }}>
            CODE of...My Name
          </div>
          <s.SpacerXSmall />
          作詞・作曲・編曲：藤末樹 <br />
          歌：ヨシカ
          <br />
          ギター：奥山アキラ
          <br />
        </s.TextDescription>
        <s.SpacerSmall />
        <s.TextDescription style={{ textAlign: "center" }}>
          <LinkButton
            href="https://sound-desert.com/"
            style={{ fontFamily: "SourceHanSerif" }}
          >
            SoundDesertはコチラ🌐
          </LinkButton>
        </s.TextDescription>

        {/* <s.TextDescription style={{ textAlign: "center" }}>
          SpreadにてPJ開始記念NFTを配布中！
          <br />
          ⬇︎⬇︎キーワードはこちら⬇︎⬇︎
          <s.SpacerSmall />
          <s.Title>10人目は加藤</s.Title>
        </s.TextDescription>
        <s.SpacerSmall />
        <Grid container style={{ textAlign: "center" }} justifyContent="center">
          <Grid item sm={3} xs={1} md={3} lg={4} />
          <Grid item sm={6} xs={10} md={6} lg={4}>
            <TwitterTweetEmbed tweetId={"1632251926892146690"} />
          </Grid>
          <Grid item sm={3} xs={1} md={3} lg={4} />
        </Grid> */}
      </s.Container>
    </>
  );
}

function Roadmap() {
  return (
    <s.Container
      flex={2}
      jc={"center"}
      ai={"center"}
      image={Cyber}
      color={"rgba(32, 32, 32, 0.9)"}
      bg_blend={"overlay"}
    >
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.Title>Roadmap</s.Title>
      <s.Divider width="100" />
      <s.SelectArmsImg src={require("./images/roadmap.png")} style={{maxWidth: "450px"}} />
      <s.SpacerLarge />
      <s.SpacerLarge />
    </s.Container>
  );
}

function Team() {
  return (
    <s.Container
      flex={2}
      jc={"center"}
      ai={"center"}
      color={"black"}
      bg_blend={"overlay"}
    >
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.SpacerLarge />
      <s.Title>OurTeam</s.Title>
      <s.Divider width="100" />
      <Grid container>
        <Grid item xs={6} sm={3}>
          <Member
            name={"ザギザギ"}
            image={require("./images/zagizagi.png")}
            twitter={"https://twitter.com/actct_zagi"}
            role={"Creator, \nCo-Founder"}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Member
            name={"なしお"}
            image={require("./images/nashio.png")}
            twitter={"https://twitter.com/nassy_nft"}
            role={"Engineer, \nCo-Founder"}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Member
            name={"すー"}
            image={require("./images/su.png")}
            twitter={"https://twitter.com/sonansusi"}
            role={"Marketer, \nCo-Founder"}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Member
            name={"KAI"}
            image={require("./images/kai.png")}
            twitter={"https://twitter.com/4hrdk1"}
            role={"Adviser"}
          />
        </Grid>
      </Grid>
      <s.SpacerLarge />
    </s.Container>
  );
}

function Member(props) {
  return (
    <>
      <s.TextDescription style={{ textAlign: "center" }}>
        <a href={props.twitter} target="_blank">
          <s.StyledMemberImg src={props.image} width="122" height="122" />
        </a>
        <s.TextName>{props.name}</s.TextName>
        <s.TextRole>
          <div style={{ whiteSpace: "pre-line" }}>{props.role}</div>
        </s.TextRole>
      </s.TextDescription>
    </>
  );
}

function Footer() {
  return (
    <s.Container
      flex={2}
      jc={"center"}
      ai={"center"}
      color={"black"}
      bg_blend={"overlay"}
    >
      <s.TextFooter>Copyright © 2023 - Project NAMES/ARMS</s.TextFooter>
    </s.Container>
  );
}

function NotFound() {
  return <h2>Not Found Page</h2>;
}

export default App;
