import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
@font-face {
	font-family: 'Torono Glitch Sans';
	src: url(${require("./ToronoGlitchSans.otf")}) format('opentype');
}
@font-face {
	font-family: 'ToronoGlitchSerif';
	src: url(${require("./ToronoGlitchSerif.otf")}) format('opentype');
}
@font-face {
	font-family: 'SourceHanSerif';
	src: url(${require("./SourceHanSerif-Regular.otf")}) format('opentype');
}
@font-face {
	font-family: 'SourceHanSerif-Bold';
	src: url(${require("./SourceHanSerif-Bold.otf")}) format('opentype');
}
@media (min-width: 572px) {
  .sp-only {
      display: none;
  }
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: var(--primary);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ color }) => (color ? color : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  background-blend-mode: ${({ bg_blend }) =>
    bg_blend ? bg_blend : "normal"}; ;
`;


export const IndexContainer = styled.div`
  background-color: ${({ color }) => (color ? color : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  background-blend-mode: ${({ bg_blend }) =>
    bg_blend ? bg_blend : "normal"}; ;
`;

export const IndexTextContainer = styled.div`
width: 100%;
  background-color: ${({ color }) => (color ? color : "rgba(2, 2, 2, 0.4)")};
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  border: 30px solid;
  border-image-source:url(${require("./text_box_mini.png")});
  border-image-slice:30;
  box-sizing: border-box;
`;
// clip-path: polygon(30px 0, 100% 0, 100% calc(100% - 30px), calc(100% - 30px) 100%, 0% 100%, 0% 30px);

export const IndexTextContainerOld = styled.div`
  background-color: ${({ color }) => (color ? color : "rgba(2, 2, 2, 0.4)")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  border: 0.2mm solid;
  border-color: white;
  background-blend-mode: ${({ bg_blend }) =>
    bg_blend ? bg_blend : "overlay"}; ;
`;


export const Firstview = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;

export const Title = styled.h2`
  color: white !important;
  margin-top: 0px;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.6;
  font-family: "Torono Glitch Sans";
`;

export const ArmsTitle = styled.h2`
  color: white !important;
  margin-top: 0px;
  font-size: 28px;
  font-weight: bold;
  line-height: 1;
  font-family: "Torono Glitch Sans";
`;

export const ArmsSubTitle = styled.h2`
  color: white !important;
  margin-top: 0px;
  font-size: 12px;
  font-weight: bold;
  line-height: 0.2;
  font-family: "Torono Glitch Sans";
`;


export const Divider = styled.hr`
  height: 0.2rem;
  max-width: 3.25rem;
  margin: 1.5rem auto;
  opacity: 1;
  border: none;
  background-color: #e3060b;
`;

export const TextBlock = styled.span`
  display: inline-block;
  _display: inline;
`;

export const TextDescription = styled.p`
  color: white;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 500;
  font-family: "SourceHanSerif";
`;

export const ArmsDetailText = styled.p`
  color: white;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;
  font-family: "SourceHanSerif";
  margin: -2%;
  text-align: "left";
`;

export const StyledIntroImgL = styled.img`
  max-width: 1100px;
  width: 100%;
`;

export const StyledIntroImg = styled.img`
  max-width: 600px;
  width: 100%;
`;

export const StyledMemberImg = styled.img`
  border-radius: 50%;
`;

export const ManafudaImg = styled.img`
  max-width: 400px;
  width: 100%;
  margin: 5px
`;

export const SlideManafudaImg = styled.img`
  max-height: 150px;
  margin: 5px
`;

export const SelectArmsImg = styled.img`
  max-width: 500px;
  width: 100%;
  margin: 0px 0px 0px 0px;
  
`;

export const OnlyArmsImg = styled.img`
  max-width: 450px;
  width: 100%;
  margin: 0;
`;

export const TextName = styled.p`
  color: white;
  font-size: 22px;
  line-height: 0.5;
  font-weight: 600;
`;

export const TextRole = styled.p`
  color: rgba(108, 117, 125);
  font-size: 16px;
  line-height: 1.4;
  font-weight: 500;
`;

export const TextFooter = styled.p`
  color: rgba(108, 117, 125);
  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;
`;